import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';
import { logout, resetProfile, setProfiles } from '../profile/profileSlice';

export const registerUser = createAsyncThunk('user/register', async ({
	firstName, lastName, email, password
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/auth/email/register', {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const forgotPassword = createAsyncThunk('user/forgot/password', async ({
	email
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/auth/forgot/password', {
			email: email
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const validateHash = createAsyncThunk('user/forgot/valid/hash', async ({
	hash
}, { rejectWithValue }) => {
	console.log(hash)
	try {
		const response = await axiosConfig.post('/auth/forgot/valid/hash', {
			hash: hash
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const resetPassword = createAsyncThunk('user/reset/password', async ({
	hash, password
}, { rejectWithValue }) => {
	try {
		console.log(hash)
		const response = await axiosConfig.post('/auth/reset/password', {
			hash: hash,
			password: password
		}, {
			withCredentials: false
		});

		return response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const createNewProfile = createAsyncThunk('user/createProfile', async ({
	firstName, lastName, email, password
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/auth/profile/create', {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password
		}, {
			withCredentials: false
		});

		// return await response.data.success;
		return {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password
		};
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});


export const completeStudentProfile = createAsyncThunk('user/profile', async ({
	userId, firstName, lastName, country, description, phone, avatar
}, { rejectWithValue }) => {
	try {
		let formData = new FormData();

		formData.append("_id", userId);
		formData.append("file", avatar[0]);
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("country", country);
		formData.append("phone", phone);

		const response = await axiosConfig.post('/users/student/create', formData, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		console.log("resr", response);
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const fillTutorLanguages = createAsyncThunk('users/tutor/informations', async ({ teach, spoken, tutorId }, { rejectWithValue }) => {
	try {
		let data = {
			_id: tutorId,
			teachs: teach,
			speaks: spoken
		}
		const response = await axiosConfig.post('/users/tutor/languages', data, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const confirmUser = createAsyncThunk('user/register/confirm', async ({ code, userId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/auth/email/confirm', {
			userId: userId,
			code: code
		}, {
			withCredentials: false
		});

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const resendConfirmUser = createAsyncThunk('user/register/confirm/resend', async ({ code, userId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/auth/email/confirm/resend', {
			userId: userId
		}, {
			withCredentials: false
		});

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const loginUser = createAsyncThunk('user/login', async ({
	email, password
}, { rejectWithValue, dispatch }) => {
	try {
		const response = await axiosConfig.post('/auth/email/login', {
			email, password
		}, { withCredentials: false });
		console.log(response.data)
		dispatch(setProfiles(response.data.profiles))
		return await response.data;
	} catch (error) {
		console.log(error.response);
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const verifyUserDetails = createAsyncThunk('user/verify', async (refreshToken, { rejectWithValue, dispatch }) => {
	try {
		const response = await axiosConfig.post('/auth/refresh', {}, {
			headers: {
				Authorization: `Bearer ${refreshToken}`,
			},
			isRefresh: true,
			withCredentials: false
		});
		let userData = await response.data;
		dispatch(setProfiles(userData.profiles))
		return userData;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const logoutUser = createAsyncThunk('user/logout', async (_, { rejectWithValue, dispatch }) => {
	try {
		const response = await axiosConfig.post('/auth/logout', {
			withCredentials: false
		});
		console.log("reset")
		dispatch(resetProfile());
		return response.data.message;

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const updateUser = createAsyncThunk('user/update', async ({ firstName, lastName, country, phone, timezone, password, avatar,email }, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;

		let formData = new FormData();

		formData.append("_id", userId);
		formData.append("file", avatar[0]);
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("country", country);
		formData.append("email", email);
		if (phone != 0)
			formData.append("phone", phone);
		formData.append("timezone", timezone);
		if (password != "")
			formData.append("password", password);

		const response = await axiosConfig.post('/users/update', formData, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return response.data;

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

// export const getCurrentUser = createAsyncThunk('user/me', async (_, { rejectWithValue, getState }) => {
// 	try {
// 		const response = await axiosConfig.post('/users/me');

// 		return response.data;

// 	} catch (error) {
// 		return rejectWithValue({
// 			error: error.response.data ? error.response.data.message : error.message
// 		});
// 	}
// });

export const getUsersList = createAsyncThunk('user/list', async (_, { rejectWithValue, dispatch }) => {
	try {
		const response = await axiosConfig.get('/users/list', {
			withCredentials: false
		});

		return response.data;

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const createAdministrator = createAsyncThunk('user/create/admin', async ({ firstName, lastName, country, phone, timezone, password, avatar, email }, { rejectWithValue, dispatch }) => {
	try {
		let formData = new FormData();

		formData.append("file", avatar[0]);
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("country", country);
		formData.append("email", email);
		if (phone != 0)
			formData.append("phone", phone);
		formData.append("timezone", timezone);
		if (password != "")
			formData.append("password", password);

		const response = await axiosConfig.post('/users/create/admin', formData, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return response.data;

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});