import { useParams } from 'react-router'
import tutors from '../../utils/fake/tutors.json'
import { ProfileOverview } from './overview'
import { Link } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { ReviewsBox } from './reviewsBox'
import { AskMe } from './askMe'
import { AppointmentBox } from './appointmentBox'
import { useEffect, useState } from 'react'
import { getFullTutorProfile, getPublicTutorProfile } from '../../store/profile/profileActions'
import { useDispatch } from 'react-redux'
import { ProfileResume } from './profileResume'
import { PublicCalendarView } from './publicCalendar'


const PublicTutorProfile = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true)
    const [profile, setProfile] = useState({}) 
    useEffect(() => {
        dispatch(getPublicTutorProfile({profileId: id}))
        .unwrap()
        .then(v => {
            setIsLoading(false);
            setProfile(v)
            console.log(v)
        }) 
    }, [])
    return (
        <>
            {!isLoading && <ul className={`2xl:w-4/6 mx-auto bg-white flex items-center gap-2 px-5 py-3 rounded-xl mb-5`}>
                <li className="flex">
                    <Link className="flex items-center no-underline gap-2 text-nt-grey" to={"/public/tutors"}>
                        <FiArrowLeft />Tutors List
                    </Link>
                    
                </li>
                <li className='text-nt-grey'>{">"}</li>
                <li>{profile.userId.firstName + " " + profile.userId.lastName}</li>
            </ul>}

            {!isLoading &&  <div className='2xl:w-4/6 mx-auto'>
                <ProfileOverview showReview={false} profile={profile} link={false} />
                <ProfileResume profile={profile} />
                {profile.reviews.length > 0 ? <ReviewsBox profile={profile} canReview={false}/> : null}
                {/* <PublicCalendarView profile={profile._id} /> */}
            </div>}
        </>
    )
}

export { PublicTutorProfile }