import { useEffect, useState } from "react"
import { ProfileOverview } from "../../components/profile/overview"
import { SearchTutorBar } from "../../components/profile/searchTutorBar"
import { useDispatch } from "react-redux"
import { searchPublicTutor } from "../../store/profile/profileActions"
import TutorsNotFoundImg from '../../assets/tutors/not-found.png'
import { FormProvider, useForm } from "react-hook-form"
// import Logo from '../../../assets/app/logo.png'

const PublicTutorList = ({ }) => {
    const dispatch = useDispatch();
    const {getValues, ...methods} = useForm();

    const [tutors, setTutors] = useState([])
    const tutorPerPage = 4;
    const [totalPages, setTotalPages] = useState(0)
    const [page, setPage] = useState(0)
    const [oldSignal, setOldSignal] = useState(null)

    useEffect(() => {
        dispatch(searchPublicTutor({signal: null, skip: page, limit: tutorPerPage}))
            .unwrap()
            .then(v => {
                setTotalPages(v.pages)
                setTutors(v.list)
            })
    }, [])

    const TutorsNotFound = () => {
        return (
            <div className="opacity-60 flex flex-col items-center justify-center">
                <img src={TutorsNotFoundImg} />
                <h4 className="text-nt-blue">No Tutors Found</h4>
            </div>
        )
    }

    const Pagination = () => {
        let pagesNumber = [];
        
        for(let i=0; i<totalPages;i++){
            pagesNumber.push(
            <span 
                key={i}
                className={`cursor-pointer w-6 text-center border border-nt-blue rounded-md ${i == page ? "bg-nt-blue text-white" : "bg-white text-nt-blue"}`}
                onClick={() => {
                    handleSearch(i)
                }}
                >
                {i+1}
            </span>)
        }
        return <div className="w-full flex justify-center gap-2">
            <button
                disabled={(page - 1) < 0}
                onClick={() => handleSearch(page - 1)} 
                className="disabled:opacity-20 cursor-pointer w-6 text-center border border-nt-blue rounded-md">{"<"}</button>
                {pagesNumber}
            <button
                disabled={(page + 1) >= totalPages}
                onClick={() => handleSearch(page + 1)} 
                className="disabled:opacity-20 cursor-pointer w-6 text-center border border-nt-blue rounded-md">{">"}</button>
        </div>
    }

    const handleSearch = (page = 0) => {
        const controller = new AbortController();
        if (oldSignal !== null) {
            oldSignal.abort()
        }
        setOldSignal(controller)
        setPage(page)
        const data = getValues();
        dispatch(searchPublicTutor({ signal: controller.signal, skip: page, limit: tutorPerPage, ...data }))
        .unwrap()
        .then(v => {
            setTotalPages(v.pages)
            setTutors(v.list)
        })
        .catch(e => console.log("e", e)) 
    }

    return (
        <div className='2xl:w-4/6 mx-auto'>
            <div className='w-full'>
                <FormProvider {...methods} > 
                    <SearchTutorBar handleSearch={handleSearch}  />
                </FormProvider>
                {tutors.length > 0 ? tutors.map((v, i) =>
                    <div className="my-5" key={i}>
                        <ProfileOverview profile={v} isPublic={true} />
                    </div>) : <TutorsNotFound />}
            </div>
           {tutors.length > 0 ? <Pagination />: null}
        </div>
    )
}

export { PublicTutorList }