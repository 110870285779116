import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Styles from './register.module.scss';
import { FileInput, SelectInput, TextInput, TextareaInput, PhoneInput } from "../../inputs";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { completeStudentProfile } from '../../../store/profile/profileActions';
import { setUser } from "../../../store/auth/authSlice";
import Logo from '../../../assets/app/logo.png'

import countries from "../../../utils/data/countries.json"
import timezones from "../../../utils/data/timezones.json"

const StudentForm = ({ setCurrentStep }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const {
        register,
        handleSubmit,
        setError,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            avatar: user.avatar,
            firstName: user.firstName,
            lastName: user.lastName,
            userId: user._id,
            country: user.country,
            timezone: user.timezone,
            phone: user.phone
        }
    });

    const onSubmit = async (data) => {
        await dispatch(completeStudentProfile({...data, userId: user._id}))
        .unwrap()
        .then((data) => {
            dispatch(setUser(data.user))
        });
        setCurrentStep(10)
    };

    const options = countries.map((country) => { return { value: country.iso, label: country.name } })
    const timezoneOptions = timezones.map((timezone) => { return { value: timezone, label: timezone } })
    return (
        <>
            <img className='m-auto mt-[40px] mb-10' src={Logo} />
            <form noValidate id="student-form" className="" onSubmit={handleSubmit(onSubmit)}>
                <FileInput
                    name="avatar"
                    label="Avatar"
                    errors={errors}
                    register={register}
                    setError={setError}
                    control={control}
                    style="avatar"
                    required={false}
                    defaultValue={watch("avatar")}
                />

                <div className="flex gap-10 mt-5">
                    <TextInput
                        name="firstName"
                        label="First Name"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                    <TextInput
                        name="lastName"
                        label="Last Name"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                </div>

                <Controller
                    control={control}
                    name={"country"}
                    className="mb-5"
                    render={({ field }) => (
                        <SelectInput
                            name="country"
                            label="Country"
                            errors={errors}
                            register={register}
                            control={control}
                            options={options}
                            onChangeSelect={event => {
                                field.onChange(event.value)
                            }}
                            validationSchema={{
                                required: "This field is required"
                            }}
                        />
                    )}
                />

                <PhoneInput
                    name="phone"
                    label="Phone"
                    errors={errors}
                    register={register}
                    control={control}
                />

                <div className="w-full flex gap-5 justify-center mb-6">

                    <button className="rounded-full bg-nt-blue h-10 w-10 flex items-center justify-center disabled:opacity-60" onClick={() => setCurrentStep(0)}>
                        <FiArrowLeft color="#fff" fontSize={20} />
                    </button>
                    <button className="rounded-full bg-nt-blue h-10 w-10 flex items-center justify-center disabled:opacity-60">
                        <FiArrowRight color="#fff" fontSize={20} />
                    </button>
                </div>
                <span className="pb-[210px]"></span>
            </form>
        </>);
}

export { StudentForm }