import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getSingleAppointment } from "../../store/appointment/appointmentActions";
import { CountdownTimer } from "../../components/appointment/counterdown";
import { getImage } from "../../utils";
import moment from "moment";
import { FiArrowLeft } from "react-icons/fi";
import { RescheduleEvent } from "../../components/appointment/reschedule";
import { CancelEvent } from "../../components/appointment/cancel";
import { ApproveEvent } from "../../components/appointment/approve";
import { ReportEvent } from "../../components/appointment/report";
import { AppointmentHistory } from "./history";
import { Toaster } from "react-hot-toast";

const AppointmentDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { currentProfile } = useSelector(state => state.profile)
    const [loading, setLoading] = useState(true);
    const [appointment, setAppointment] = useState(null);
    const [isReschedule, setIsReschedule] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [isReport, setIsReport] = useState(false);

    const [isSendingReview, setIsSendingReview] = useState(false);
    const [reviewResult, setReviewResult] = useState(false);
    useEffect(() => {
        handleInit();
    }, [])

    const handleInit = () => {
        setIsReschedule(false)
        dispatch(getSingleAppointment(id))
            .unwrap()
            .then((v) => {
                // console.log(v)
                setAppointment(v)
                setLoading(false)
            })
    }

    const handleReview = (result) => {
        setIsSendingReview(true)
        setReviewResult(result)
    }

    const dispatchReview = () => {
        
    }

    const Info = () => {
        return <div className="flex flex-col md:flex-row justify-between items-center p-4">
            <div className="flex gap-4 items-center">
                <img src={getImage(appointment.avatar, "AVATAR")} className="w-14 object-cover h-14 rounded-full" />
                <div className="">
                    <h4 className="text-nt-blue capitalize">{appointment.fullname}</h4>
                    <span className="text-nt-grey text-sm">Date: {moment(appointment.date).utc().format("YYYY-MM-DD HH:mm")}</span>
                </div>
            </div>
            <div className="flex flex-row-reverse gap-1">
                <StatusTag />
                <h3 className="text-nt-blue mt-1 text-right">{appointment.price} €</h3>
            </div>
        </div>
    }

    const Pending = () => {
        return <div className="">
            <Info />
            <div>
                <CountdownTimer target={moment(appointment.dateUtc).parseZone()} text="Course Start In" />
            </div>
            {(!isReschedule && !isCancel) && <div className="flex justify-center gap-5 mb-5">
                <button onClick={() => setIsReschedule(true)} className="w-32 py-2 bg-nt-blue rounded text-white">Reschedule</button>
                <button onClick={() => setIsCancel(true)} className="w-32 py-2 bg-nt-red rounded text-white">Cancel</button>
            </div>}
            {
                isReschedule && <RescheduleEvent refreshParent={() => handleInit()} setIsReschedule={(v) => setIsReschedule(v)} event={appointment} />
            }
            {
                isCancel && <CancelEvent refreshParent={() => handleInit()} setIsCancel={(v) => setIsCancel(v)} event={appointment} />
            }
        </div>
    }

    const PendingPayment = () => {
        return <div className="">
            <Info />
            <div>
                <CountdownTimer text="Payment Due In" target={moment(appointment.dateUtc).parseZone().add(50, "minutes").add(2, "day")} />
            </div>
            {(!isApprove && !isReport) && <div className="flex justify-center gap-5 mb-5">
                {(currentProfile.role && currentProfile.role == 3) ? <button className="w-40 py-2 bg-nt-blue rounded text-white" onClick={() => setIsApprove(true)}>Approve Payment</button> : ""}
                <button className="w-40 py-2 bg-nt-red rounded text-white" onClick={() => setIsReport(true)}>Report Issue</button>
            </div>}
            {
                isApprove && <ApproveEvent refreshParent={() => handleInit()} setIsApprove={(v) => setIsApprove(v)} event={appointment} />
            }
            {
                isReport && <ReportEvent refreshParent={() => handleInit()} setIsReport={(v) => setIsReport(v)} event={appointment} />
            }
        </div>
    }

    const Done = () => {
        return <div className="">
            <Info />
        </div>
    }

    const Canceled = ({by}) => {
        return <div className="">
            <Info />
            <h4 className="text-nt-grey text-center">Canceled By {by}</h4>
        </div>
    }

    const UnderReview = () => {
        return <div className="">
            <Info />
            <h4 className="text-nt-grey text-center">Payment Under review</h4>
            {currentProfile?.role == 1 ?
                <div className="mt-5 pt-3 border-t-2">
                    <ReviewAction />
                </div> 
            : null}
        </div>
    }

    const ReviewAction = () => {
        return !isSendingReview ? <div className="flex justify-center gap-5">
            <button className="text-white bg-nt-blue py-2 px-4 rounded-md" onClick={() => handleReview(true)}>Pay Tutor</button>
            <button className="text-white bg-nt-red py-2 px-4 rounded-md" onClick={() => handleReview(false)}>Refund student</button>
        </div> : <div className="">
            <p className="text-nt-blue text-center mb-3">{reviewResult ? "Are you sure to pay tutor ?" : "Are you sure to refund student ?"}</p>
            <div className="justify-center flex gap-5">
                <button onClick={() => dispatchReview()} className="text-white bg-nt-blue py-2 px-4 rounded-md">Yes</button>
                <button onClick={() => setIsSendingReview(false)} className="text-nt-blue bg-nt-grey-light py-2 px-4 rounded-md">Cancel</button>
            </div>
        </div>
    }

    const PaymentRejected = () => {
        return <div className="">
            <Info />
            <h4 className="text-nt-grey text-center">Payment Rejected</h4>
        </div>
    }

    const StatusTag = () => {
        const status = appointment.status;
        if (status === -1) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending</span>
        } else if (status === 0) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending Payment</span>
        } else if (status === 1) {
            return <span className={`bg-green text-white px-2 rounded py-1`}>Done</span>
        } else if (status === 2) {
            return <span className={`bg-red text-white px-2 rounded py-1`}>Canceled</span>
        } else if (status === 3) {
            return <span className={`bg-green opacity-70 text-white px-2 rounded py-1`}>Under Review</span>
        } else if (status === 4) {
            return <span className={`bg-red opacity-70 text-white px-2 rounded py-1`}>Payment Rejected</span>
        }
    }
    const navigate = useNavigate()
    const Details = () => {
        let globalStatus = null;
        // console.log(appointment.cancel?.by, currentProfile._id, appointment.cancel?.by== currentProfile._id)
        switch (appointment.status) {
            case -1:
                globalStatus = <Pending />;
                break;
            case 0:
                globalStatus = <PendingPayment />;
                break;
            case 1:
                globalStatus = <Done />;
                break;
            case 2:
                globalStatus = <Canceled by={
                    appointment.cancel?.by == currentProfile._id ? "you" : appointment.otherName 
                } />;
                break;
            case 3:
                globalStatus = <UnderReview />;
                break;
            case 4:
                globalStatus = <PaymentRejected />;
                break;
        }

        return (
            <>
                <button className="flex items-center no-underline gap-2 text-nt-grey" onClick={() => navigate(-1)}>
                    <FiArrowLeft />Back
                </button>

                {globalStatus}
                <AppointmentHistory otherName={appointment.otherName} history={[
                    ...(appointment.report && appointment.report.by ? [{ ...appointment.report, type: "report" }] : []),
                    ...(appointment.cancel ? [{ ...appointment.cancel, type: "cancel" }] : []),
                    ...(appointment.approve ? [{ ...appointment.approve, type: "approve" }] : []),
                    ...(appointment.reschedule ? [...appointment.reschedule.map(v => ({ ...v, type: "reschedule" }))] : [])
                ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))}
                />
            </>
        )
        // if (status === -1) {
        //     return <Pending />
        // } else if (status === 0) {
        //     return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending Payment</span>
        // } else if (status === 1) {
        //     return <span className={`bg-green text-white px-2 rounded py-1`}>Done</span>
        // } else if (status === 2) {
        //     return <span className={`bg-red text-white px-2 rounded py-1`}>Canceled</span>
        // } else if (status === 3) {
        //     return <span className={`bg-green opacity-70 text-white px-2 rounded py-1`}>Under Review</span>
        // } else if (status === 4) {
        //     return <span className={`bg-red opacity-70 text-white px-2 rounded py-1`}>Payment Rejected</span>
        // }
        // if(status === -1){
        //     return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending</span>
        // }else if(status === 0){
        //     return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending Payment</span>
        // }else if(status === 1){
        //     return <span className={`bg-green text-white px-2 rounded py-1`}>Done</span>
        // }else if(status === 2){
        //     return <span className={`bg-red text-white px-2 rounded py-1`}>Canceled</span>
        // }else if(status === 3){
        //     return <span className={`bg-green opacity-70 text-white px-2 rounded py-1`}>Under Review</span>
        // }else if(status === 4){
        //     return <span className={`bg-red opacity-70 text-white px-2 rounded py-1`}>Payment Rejected</span>
        // }
    }
    return <div className="bg-white xl:min-w-[740px] lg:w-2/3 mx-auto rounded-2xl p-4">
        {!loading ?
            <>
            <Toaster />
            <Details status={appointment.status} />
            </>
            : ''}
    </div>
}
export { AppointmentDetails }