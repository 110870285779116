import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateToSlice, getMomentDate, NTformatDate } from "../../utils";
import { getDayAvailability, rescheduleSingleAppointment } from "../../store/appointment/appointmentActions";
import { Controller, useForm } from "react-hook-form";
import Calendar from "react-calendar";
import ReactSelect from "react-select";

const RescheduleEvent = ({ setIsReschedule, event, refreshParent }) => {
    const [availableOptions, setAvailableOptions] = useState([]);
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch();

    const getDayAvailabilityAction = (day) => {
        setAvailableOptions([]);
        dispatch(getDayAvailability({
            profile: event.tutor,
            day: NTformatDate(day)
        }))
            .unwrap()
            .then(v => {

                let slices = v.map((slice => {
                    return {
                        value: getMomentDate(slice.date),
                        label: dateToSlice(slice.date)
                    };
                }))
                setAvailableOptions(slices)
            })
    }

    const { handleSubmit, control, watch, register, setValue } = useForm()

    const handleReschedule = (data) => {
        dispatch(rescheduleSingleAppointment(data))
        .unwrap()
        .then(v => refreshParent())
    }

    const IssueOptions = [
        {value: 0,label: "The student asked to reschedule"},
        {value: 1,label: "Health issues"},
        {value: 2,label: "I am taking leave"},
        {value: 3,label: "I wish to change my rates"},
        {value: 4,label: "I have another commitment"},
        {value: 5,label: "I have a technical problem"},
        {value: 6,label: "Other"},
    ]

    return <div>
        <form className="flex flex-col gap-5" onSubmit={handleSubmit((data) => handleReschedule(data))}>
            <h3 className="text-nt-blue">Reschedule Course</h3>
            <div className="flex flex-col lg:flex-row gap-2">
                <div className="w-full lg:w-1/2">
                    <Controller
                        control={control}
                        name="date"
                        render={({ field: { onChange, value } }) => (
                            <Calendar className={"nt-calendar w-full mx-auto"}
                                onChange={(e) => { onChange(e); getDayAvailabilityAction(e); setValue("time", null) }}
                                view='month'
                                minDate={moment().add(1, "day").add(1, "hour").toDate()}
                                maxDate={moment().add(1, "year").add(1, "hour").toDate()}
                            />
                        )}
                    />


                </div>
                <div className="w-full lg:w-1/2">
                    <Controller
                        control={control}
                        name="time"
                        render={({ field: { onChange, value } }) => (
                            <ReactSelect
                                className="w-full"
                                placeholder="Time"
                                required="true"
                                onChange={onChange}
                                value={value || ''}
                                options={availableOptions}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="reason"
                        render={({ field: { onChange, value } }) => (
                            <ReactSelect
                                className="w-full mt-5"
                                placeholder="Reason"
                                required={true}
                                onChange={onChange}
                                value={value || ''}
                                options={IssueOptions}
                            />
                        )}
                    />
                    <input name="_id" {...register("_id")} type="hidden" value={event._id} />
                    <textarea name="message" {...register("message")} rows={6} className="w-full mt-5 flex-grow border-nt-grey border-2 rounded p-1" placeholder="Message"></textarea>
                </div>
            </div>
            <div className="flex justify-center gap-5">
                <button className="w-40 bg-nt-blue text-white px-2 py-3 rounded">Reschedule</button>
                <button type="button" className="w-40 bg-nt-grey text-white px-2 py-3 rounded" onClick={() => setIsReschedule(false)}>Cancel</button>
            </div>
        </form>

    </div>
}
export { RescheduleEvent }