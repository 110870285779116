import React, { useContext, useEffect, useRef, useState } from "react";
import styled from '@emotion/styled'
import { Virtuoso } from "react-virtuoso";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { deleteChatMessage, getConversationList, sendChatFile, sendChatMessage } from "../../store/chat/chatActions";
import { IoTextOutline } from "react-icons/io5";
import { FiArrowRight, FiCalendar, FiDownload, FiPaperclip, FiSend, FiTrash } from "react-icons/fi";
import { SocketContext } from "../../utils/socketConfig";
import { errorToast, getImage } from "../../utils";
import moment from "moment";
import { Link } from 'react-router-dom';

function ChatList({ userId, messages = [], onSend, onDelete, pushFile }) {

    const [type, setType] = useState(0)
    const ref = useRef(null);
    const messageArea = useRef(null);
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const [file, setFile] = useState(null);
    const [isDelete, setIsDelete] = useState(-1);

    useEffect(() => {
        socket.on("message", (v) => onSend(v))
        socket.on("DELETE_MSG", (v) => onDelete(v))
    }, [])

    const deleteMessage = (index) => {
        setIsDelete(index);
    }

    const confirmDelete = (id) => {
        dispatch(deleteChatMessage(id))
            .unwrap()
            .then((v) => {
                onDelete(v)
            })
    }

    const cancelDelete = () => {
        setIsDelete(-1);
    }


    const sendMessage = () => {
        if (type === 0) {
            if (messageArea.current.value.trim() !== "") {
                dispatch(sendChatMessage(messageArea.current.value))
                    .unwrap()
                    .then((v) => {
                        messageArea.current.value = "";
                        onSend(v)
                        dispatch(getConversationList())
                    })
            }
        } else if (type === 1) {
            if (file) {
                dispatch(sendChatFile(file))
                    .unwrap()
                    .then((v) => {
                        setType(0)
                        onSend(v)
                    })
            }
        }
    }

    const Message = ({ rowData, fromUser, showDate, index }) => {
        let time = moment(rowData.createdAt)

        return (
            <>
                {showDate && <p className='justify-self-center mb-5 bg-nt-grey-light rounded-xl px-2 py-1 mx-auto w-fit'>{time.format("YYYY-MM-DD")}</p>}
                <div className={`${fromUser ? "max-w-[80%] justify-self-end pb-2 pe-2" : "justify-self-start max-w-[80%] pb-2"}`}>
                    <div className='flex gap-2 relative items-end'>
                        {(fromUser && isDelete !== index && !rowData.isReschedule && !rowData.isCancel) && <span className="min-w-[30px]">
                            <FiTrash onClick={() => deleteMessage(index)}
                                className="ml-2 text-nt-red cursor-pointer" size={20} />
                        </span>}
                        {(fromUser && isDelete === index) && <span className="min-w-[30px]">
                            <span className="absolute bottom-0 left-0 bg-nt-grey-light px-2 rounded bg-opacity-70">
                                <span className="cursor-pointer" onClick={() => confirmDelete(rowData._id)}>Yes</span> - <span className="cursor-pointer" onClick={() => cancelDelete()}>No</span>
                            </span>
                        </span>}
                        {rowData.isReschedule ?
                            <div className={`rounded-xl bg-nt-grey-light text-nt-blue px-2 py-2 w-full flex items-center`}>
                                <div className="border-r-2 pr-2 border-nt-grey">
                                    <h5 className="font-medium flex gap-1 items-center"><FiCalendar />Rescheduled Course</h5>
                                    {rowData.message}
                                </div>
                                <Link to={"/appointment/detail/" + rowData.reschedule}>
                                    <FiArrowRight className="ml-2" size={20} />
                                </Link>
                            </div> :
                            rowData.isCancel ?
                            <div className={`rounded-xl bg-nt-grey-light text-nt-blue px-2 py-2 w-full flex items-center`}>
                                <div className="border-r-2 pr-2 border-nt-grey">
                                    <h5 className="font-medium flex gap-1 items-center"><FiCalendar />Canceled Course</h5>
                                    {rowData.message}
                                </div>
                                <Link to={"/appointment/detail/" + rowData.reschedule}>
                                    <FiArrowRight className="ml-2" size={20} />
                                </Link>
                            </div>:
                            <p className={`rounded-xl bg-nt-blue text-white px-2 py-2 w-full`} dangerouslySetInnerHTML={{__html: rowData.message.replace("\n", "<br/>")}}></p>}
                    </div>
                    <p className="float-right">{time.format("HH:mm")}</p>
                </div>
            </>
        )
    }

    const File = ({ rowData, fromUser, showDate, index }) => {
        let time = new Date(rowData.createdAt);
        return (
            <>
                {showDate && <p className='justify-self-center mb-5 bg-nt-grey-light rounded-xl px-2 py-1 mx-auto w-fit'>{time.getFullYear() + "-" + time.getMonth() + "-" + time.getDate()}</p>}
                <div className={`${fromUser ? "max-w-[80%] justify-self-end pb-2 pe-2" : "justify-self-start max-w-[80%] pb-2"}`}>
                    <div className='flex gap-2 relative items-end'>
                        {(fromUser && isDelete !== index) && <span className="min-w-[30px]">
                            <FiTrash onClick={() => deleteMessage(index)}
                                className="ml-2 text-nt-red cursor-pointer" size={20} />
                        </span>}

                        {(fromUser && isDelete === index) && <span className="min-w-[30px]">
                            <span className="absolute bottom-0 left-0 bg-nt-grey-light px-2 rounded bg-opacity-70">
                                <span className="cursor-pointer" onClick={() => confirmDelete(rowData._id)}>Yes</span> - <span className="cursor-pointer" onClick={() => cancelDelete()}>No</span>
                            </span>
                        </span>}

                        <a href={getImage(rowData.file.path)} download={rowData.file.name}
                            className='no-underline cursor-pointer rounded-xl flex bg-nt-grey-light text-nt-blue font-semibold py-2 w-full'>
                            <span className="px-2 border-e-2 border-nt-grey">{rowData.file.name}</span>
                            <FiDownload className="mx-2" size="20" />
                        </a>
                    </div>
                    <p className="float-right">{time.getHours() + ':' + time.getMinutes()}</p>
                </div>
            </>
        )
    }

    const Deleted = ({ rowData, fromUser, showDate, index }) => {
        let time = new Date(rowData.createdAt);
        return (
            <>
                {showDate && <p className='justify-self-center mb-5 bg-nt-grey-light rounded-xl px-2 py-1 mx-auto w-fit'>{time.getFullYear() + "-" + time.getMonth() + "-" + time.getDate()}</p>}
                <div className={`${fromUser ? "max-w-[80%] justify-self-end pb-2 pe-2" : "justify-self-start max-w-[80%] pb-2"}`}>
                    <div className='flex gap-2 items-end'>
                        Deleted Message
                    </div>
                </div>
            </>
        )
    }

    const row = React.useMemo(
        () => (i, message) => {
            const fromUser = message.sender === userId;
            if (message.hidden)
                return <Deleted index={i} showDate={i == 0 || new Date(message.createdAt).getDate() - new Date(messages[i - 1].createdAt).getDate() > 0} rowData={message} fromUser={fromUser} />
            return !message.isFile ?
                <Message index={i} showDate={i == 0 || new Date(message.createdAt).getDate() - new Date(messages[i - 1].createdAt).getDate() > 0} rowData={message} fromUser={fromUser} />
                : <File index={i} showDate={i == 0 || new Date(message.createdAt).getDate() - new Date(messages[i - 1].createdAt).getDate() > 0} rowData={message} fromUser={fromUser} />
        },
        [messages.length, isDelete]
    );

    const Item = styled.div`
        display:grid;
        width:100%;
    `;
    const fileSizeError = () => {
        errorToast("Max File Size : 5MB");
    }
    return (
        <div className="flex grow flex-col">
            <Virtuoso
                ref={ref}
                className="flex-1 pb-5"
                initialTopMostItemIndex={messages.length - 1}
                followOutput="smooth"
                components={{ Item }}
                itemContent={row}
                data={messages}
            />
            <div className='flex mt-5'>
                {type === 0 && 
                    <form className="w-full" onSubmit={() => sendMessage()}>
                        <textarea ref={messageArea} className='p-2 border-2 border-nt-blue rounded-xl h-[100px] w-full'></textarea>
                    </form>
                }
                {type === 1 ?  <FileUploader 
                    handleChange={(file) => setFile(file)} 
                    name="file"     
                    classes="w-full min-w-[200px] max-w-[100%] h-[100px] file-uploader"
                    maxSize={2}
                    onSizeError={() => fileSizeError()} 
                    uploadedLabel="Uploaded Successfully!" /> : null}
                <div className='flex flex-col ps-4 items-center justify-between'>
                    <div className='bg-nt-blue text-white cursor-pointer p-2 rounded' onClick={() => sendMessage()}>
                        <FiSend color='#fff' size={20} />
                    </div>
                    <div className='bg-nt-grey-light cursor-pointer text-nt-blue p-2 rounded'>
                        {type == 1 ? <IoTextOutline size={20} onClick={() => setType(0)} /> : <FiPaperclip size={20} onClick={() => setType(1)} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

const VirtuosoMsg = ({ messagesList, pushFile }) => {
    const [messages, setMessages] = React.useState(messagesList);
    const { currentProfile } = useSelector((state) => state.profile)
    const currentChat = useSelector((state) => state.chat.currentChat)

    return (
        <div className="w-full grow flex flex-col">
            <ChatList
                pushFile={(v) => pushFile(v)}
                messages={messages}
                userId={currentProfile._id}
                onSend={(message) => {
                    if(currentChat._id == message.chat )
                        setMessages((x) => [...x, message])
                }
                }
                onDelete={(msg) => setMessages((v) => v.map((m) => {
                    return (m._id === msg._id ? msg : m)
                }))}
            />
        </div>
    );
}
export { VirtuosoMsg }