import Styles from '../../components/profile/profile.module.scss'
import Langs from '../../utils/data/langs.json'
import YoutubeEmbed from '../../components/profile/youtube'
import { RatingStars } from './ratingStars'
import { Link } from 'react-router-dom'
import { getImage } from '../../utils'

const ProfileOverview = ({ id, profile, link = true, showStats = true, isPublic = false }) => {
    const getLangName = (key) => {
        return Langs.find(item => {
            return item.value == key
        })?.name;
    }

    const LangTag = ({ lang }) => {
        return (lang.name?.trim() !== "" && lang.level?.trim() !== "") ?
            <div className={Styles.lang}>
                <span className={Styles.langName}>{getLangName(lang.lang)}</span>
                <span className={Styles.langLevel}>{lang.level}</span>
            </div> : ''
    }

    return (
        <div className={Styles.profileContainer}>
            <div className={Styles.generalInformations}>
                <div className={`flex-col justify-between ${Styles.leftSide}`}>
                    <div className='flex gap-3'>
                        <div className={Styles.avatar + " shrink-0"}>
                            <img src={getImage(profile.userId.avatar?.path, "AVATAR")} className='object-cover h-[100px] w-[100px]'/>
                        </div>
                        <div className={Styles.infos}>
                            <h3 className='capitalize'>
                                {profile.userId.firstName + " " + profile.userId.lastName}
                                {profile.userId.country?.length && <img src={`https://flagsapi.com/${profile.userId.country}/shiny/24.png`} />}
                            </h3>
                            {profile.teach.length && <div className={Styles.teaches}>
                                <div className={Styles.title}>Teaches:</div>
                                <div className={Styles.listContainer}>
                                    {
                                        profile.teach.map((lang, i) => {
                                            return (
                                                <LangTag lang={lang} key={i} />
                                            )
                                        })
                                    }
                                </div>
                            </div>}
                            {profile.speak.length && <div className={Styles.speaks}>
                                <div className={Styles.title}>Speak:</div>
                                <div className={Styles.listContainer}>
                                    {
                                        profile.speak.map((lang, i) => {
                                            return (
                                                <LangTag lang={lang} key={i} />
                                            )
                                        })
                                    }
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className='text-nt-blue font-bold'>
                        {profile.teach[0].price + " € | Course"}
                    </div>
                    {showStats ? 
                        <ul className='text-nt-blue justify-between flex w-full flex-wrap md:flex-nowrap text-sm lg:text-base mx-auto gap-1 xl:gap-5 my-5'>

                            <li className='border-e-2 w-[48%] md:w-full'>
                                <span className='font-bold '>Reviews <span className='font-light'>({profile.globalReviews.count})</span></span>
                                {/* <RatingStars active={profile.stars.global} /> */}
                                <RatingStars active={profile.globalReviews.stars} />
                            </li>
                            <li className='md:border-e-2 w-[48%] md:w-full'>
                                <span className='font-bold '>Lessons</span>
                                <p>{profile.lessons | 0}</p>
                            </li>
                            <li className='border-e-2 w-[48%] md:w-full'>
                                <span className='font-bold '>Students</span>
                                <p>{profile.students | 0}</p>
                            </li>
                            <li className='w-[48%] md:w-full'>
                                <span className='font-bold '>Attendance</span>
                                <p>{(profile.attendance && profile.attendance != 0) ? profile.attendance + "%": "100%"}</p>
                            </li>
                        </ul> 
                        : ''}
                </div>
                <div className={Styles.rightSide}>
                    {profile.youtube?.length ? <YoutubeEmbed url={profile.youtube} /> : ""}

                    {link && <Link to={isPublic ? "/public/tutor/" + profile._id : "/tutor/" + profile._id} className='block no-underline text-white w-full self-center mt-5 bg-nt-blue rounded text-center px-4 py-2'>
                        View Profile
                    </Link>}
                </div>
            </div>
        </div>
    )
}

export { ProfileOverview }