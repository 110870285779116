import { useDispatch, useSelector } from 'react-redux';
import {
	FiArrowRight,
} from 'react-icons/fi';
import { logoutUser } from '../../../store/auth/authActions';
import { useState } from 'react';
import { StudentForm } from './studentForm';
import { TutorForm } from './tutorForm';
import { RightText } from './rightText';

import Styles from './register.module.scss';

import StudentPicture from '../../../assets/auth/register/student.svg'
import TutorPicture from '../../../assets/auth/register/teacher.svg'
import Logo from '../../../assets/app/logo.png'
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const RegisterForm = () => {
	const dispatch = useDispatch();
	const [type, setType] = useState(1);
	const [terms, setTerms] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const logoutHandler = () => {
		dispatch(logoutUser());
	};

	const { profiles } = useSelector((state) => state.profile);
 
	const nextStep = () => {
		if(type === 1) setCurrentStep(1)
		else if(type === 2){
			const tutorExist = profiles.filter(v => v.role === 2)[0];
			const canCreateTutor = (tutorExist == undefined || tutorExist.status === undefined || tutorExist.status === -1)
			if(canCreateTutor){
				setCurrentStep(2);
			}else{
				setCurrentStep(10)
			}
		}
	}
	const UserType = () => {
		return useMemo(() => <div className='select-none'>
			<img className='m-auto my-10' src={Logo} />
			<h1 className='text-center mb-10 text-nt-blue'>Hello 👋</h1>
			<h2 className='text-center mb-5 text-nt-blue'>Are you a student or a teacher?</h2>
			<div className={Styles.userType}>
				<div className={type == 1 ? Styles.activeType : ''} >
					<img className='p-6' src={StudentPicture} onClick={() => setType(1)} />
					<span className='bottom-0 left-0 mx-auto absolute w-full'>Student</span>
				</div>
				<div className={type == 2 ? Styles.activeType : ''} >
					<img className='p-6' src={TutorPicture} onClick={() => setType(2)} />
					<span className='bottom-0 left-0 mx-auto absolute w-full'>Teacher</span>
				</div>
			</div>
			<p className='flex items-baseline w-full mt-5 text-nt-blue'>
				<input checked={terms} onChange={(v) => setTerms((t) => !t)} className='mr-2' type='checkbox' id='terms' />
				<label className='select-none' htmlFor="terms">I agree to the <a target='_blank' className='text-nt-blue' href='https://nativtalk.com/terms-and-conditions/'>Terms and Conditions.</a></label>
				
			</p>
			<div className={Styles.navigation}>
				<button disabled={!terms} onClick={() => nextStep()} >
					<FiArrowRight color="#fff" fontSize={20} />
				</button>
			</div>
		</div>, []);
	};

	const Thanks = () => {
		return (
			<>
				
				{type === 1 ? <div className={Styles.thanksMessage}>
					<img className='m-auto my-10' src={Logo} />
					<p style={{ fontSize: "50px" }}>🎉</p>
					Welcome to our diverse language platform! <br /> We're excited to have you on board
					<Link to={"/"}>Home page</Link>
				</div>
					: <div className={Styles.thanksMessage}>
						<img className='m-auto my-10' src={Logo} />
						<p style={{ fontSize: "50px" }}>🎉</p>
						Welcome to our diverse language platform! <br />
						Your accout is under review!
					</div>}
			</>
		)
	};

	return (
		<div className={Styles.ntwizard}>
			<div className={`px-4 sm:px-0 w-full xl:w-1/2 ${(currentStep >= 2 && currentStep <= 6) ? 'w-full flex' : ''}`}>
				<button className='lg:hidden absolute top-10 right-5 text-nt-blue' onClick={() => logoutHandler()}>Logout</button>
				{[0, 1].includes(currentStep) && <div className={`max-w-[500px] mx-auto ${(currentStep >= 2 && currentStep <= 6) ? Styles.tutor : ''}`}>
					{currentStep === 0 && <UserType />}
					{currentStep === 1 && <StudentForm setCurrentStep={setCurrentStep} />}
				</div>}
				{(currentStep >= 2 && currentStep <= 6) && <TutorForm currentStep={currentStep} setCurrentStep={setCurrentStep} />}
				{currentStep === 10 && <Thanks />}
			</div>
			{([0, 1, 10].includes(currentStep) || type === 1) && 
				<div className="hidden lg:flex w-1/2 animate-bg-spin-1 bg-[length:400%_400%] items-center fix h-screen top-0 right-0 bg-gradient-to-br from-nt-blue to-nt-red" >
				<button className='absolute top-5 right-5 text-white' onClick={() => logoutHandler()}>Logout</button>
				<RightText />
			</div>}
		</div>

	);
};
export { RegisterForm };
