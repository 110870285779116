import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addWithdrawRequet, getWalletStats } from "../../store/wallet/walletActions";
import { TextInput } from "../../components/inputs";
import { errorToast } from "../../utils";
import { Toaster } from "react-hot-toast";

const WithdrawRequest = ({setIsRequest, initView}) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { balance } = useSelector((state) => state.wallet);
    const { handleSubmit, 
        reset, 
        formState: { errors }, 
        register, 
        control, 
    } = useForm()

    const onAddRequest = (data) => {
        setIsDisabled(true);
        dispatch(addWithdrawRequet(data))
            .unwrap()
            .then(() => {
                dispatch(getWalletStats())
                initView()
                setIsDisabled(false)
                setIsRequest(false)
                reset()
            })
            .catch((e) => {
                console.log(e)
                errorToast(e.error)
            })
    }

    return <div className="bg-white rounded-2xl p-4 mx-auto 2xl:w-1/2 sm:w-3/4">
        <Toaster />
        <h2 className="text-nt-blue mb-5">New Widthdraw Request</h2>
        <form onSubmit={handleSubmit(onAddRequest)}>
            <TextInput
                name="email"
                label="Paypal Email"
                type="email"
                errors={errors}
                defaultValue={user.email}
                register={register}
                control={control}
                validationSchema={{
                    required: "This field is required"
                }}
            />
            <div className="flex flex-col sm:flex-row items-center sm:gap-5 mb-5 sm:mb-0">
                <TextInput
                    name={`amount`}
                    label={"Amount"}
                    errors={errors}
                    inputClassname="w-full"
                    placeholder="10"
                    type="number"
                    register={register}
                    minHtml={10}
                    maxHtml={balance}
                    control={control}
                    validationSchema={{
                        required: "This field is required",
                        validate: (value) => {
                            return value < 10 ? "Minimum value is 10" : value > balance ? "Maximum value is " + balance : true;
                        }
                    }}
                />
                <span className="p-1 px-2 rounded-md text-center block bg-nt-grey text-white">Your balance {balance}€</span>
            </div>
            <p className="text-sm text-nt-blue mb-4"><b>Note:</b> PayPal fees are on you.</p>
            <div className="flex gap-2 justify-center">
                <button disabled={isDisabled} className="bg-nt-blue w-36 text-white p-2 rounded">Send Request</button>
                <button type="button" onClick={() => setIsRequest(false)} className="w-36 bg-nt-red text-white p-2 rounded">Cancel</button>
            </div>
        </form>
    </div>
}

export { WithdrawRequest }