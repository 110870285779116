import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateToServerFormat, dateToSlice, errorToast, getMomentDate } from "../../utils";
import { reportSingleAppointment } from "../../store/appointment/appointmentActions";
import { Controller, useForm } from "react-hook-form";
import Calendar from "react-calendar";
import ReactSelect from "react-select";
import { FileUploader } from "react-drag-drop-files";

const ReportEvent = ({ setIsReport, event, refreshParent }) => {

    const dispatch = useDispatch();

    const { handleSubmit, control, watch, register, setValue } = useForm()
    const [file, setFile] = useState(null)
    const handleReport = (data) => {
        let report = {id: data._id, reason: data.reason.label, more: data.more} 
        if(file){
            report.file = file
        }            
        dispatch(reportSingleAppointment(report))
        .unwrap()
        .then(v => refreshParent())
    }

    const {currentProfile} = useSelector(state => state.profile)
    const IssueOptions = [
        ...(currentProfile.role == 2 ? [
            { value: 0, label: "Student absent" },
            { value: 5, label: "Student have a technical problem" },
            { value: 6, label: "Other" },
        ]
            : currentProfile.role == 3 ?
                [
                    { value: 0, label: "Tutor absent" },
                    { value: 5, label: "Student have a technical problem" },
                    { value: 6, label: "Other" },
                ]
                : []
        )
    ]
    const reason = watch('reason');
    const fileSizeError = () => {
        errorToast("Max File Size : 5MB");
    }
    return <div>
        <form className="flex flex-col gap-5" onSubmit={handleSubmit((data) => handleReport(data))}>
            <h3 className="text-nt-blue">Report Issue</h3>
            <div className="flex">
                <div className="w-full">
                    <Controller
                        control={control}
                        name="reason"
                        render={({ field: { onChange, value } }) => (
                            <ReactSelect
                                className="w-full"
                                placeholder="Reason"
                                required={true}
                                onChange={onChange}
                                value={value || ''}
                                options={IssueOptions}
                            />
                        )}
                    />
                    <textarea name="message" required={(reason && reason.value == 6)} {...register("more")} rows={6} className="w-full mt-5 flex-grow border-nt-grey border-2 rounded p-1" placeholder="More Informations"></textarea>
                    <FileUploader 
                        handleChange={(file) => setFile(file)} 
                        name="file"     
                        classes="mt-4 w-full max-w-[100%] h-[100px] file-uploader"
                        maxSize={2}
                        onSizeError={() => fileSizeError()} 
                        uploadedLabel="Uploaded Successfully!" />
                    <input name="_id" {...register("_id")} type="hidden" value={event._id} />
                </div>
            </div>
            <div className="flex justify-center gap-5">
                <button className="w-40 bg-nt-blue text-white px-2 py-3 rounded">Report</button>
                <button type="button" className="w-40 bg-nt-grey text-white px-2 py-3 rounded" onClick={() => setIsReport(false)}>Cancel</button>
            </div>
        </form>

    </div>
}
export { ReportEvent }