import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { DashboardAdmin } from './dashboardAdmin';
import { DashboardTutor } from './dashboardTutor';
import { DashboardStudent } from './dashboardStudent';

const Home = () => {
	const { currentProfile } = useSelector((state) => state.profile);

	return (
		!currentProfile ? <Navigate to={{ pathname: '/create/profile' }} replace />
			: currentProfile.role === 1 ? <DashboardAdmin />
				: currentProfile.role === 2 ? <DashboardTutor /> : <DashboardStudent />
	);
};

export { Home };
