import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOrderData } from "../../store/appointment/appointmentActions";
import { getImage } from "../../utils";
import moment from "moment";
import { FiCheck, FiX } from "react-icons/fi";

const PaymentResult = () => {
    let { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const [order, setOrder] = useState(false)
    const {user} = useSelector(state => state.auth)

    useEffect(() => {  
        dispatch(getOrderData(id))
            .unwrap()
            .then(v => setOrder({...v, 
                appointments: v.appointments.map(k => ({...k,date: moment(k.date).local().format("YYYY-MM-DD HH:mm").toString()}))
            }))
    }, [])
    return <>
        {order !== false ? <div className="bg-white p-2 w-full md:w-2/3 xl:w-1/3 rounded-2xl mx-auto">
            <div className="">
                {order.status == 1 ?
                    <div className="flex p-2 m-2 gap-2 rounded-lg bg-nt-blue bg-opacity-90 text-white items-center">
                        <FiCheck size={20} />
                        <h6>Payment Approved</h6>
                    </div>
                    : <div className="flex p-2 m-2 gap-2 rounded-lg bg-nt-red bg-opacity-90 text-white items-center">
                        <FiX  size={20} />
                        <h6>Payment Rejected</h6>
                    </div>}
            </div>
            <div className="p-2 px-4 flex justify-between items-start">
                <div className="flex items-center gap-3">
                    <img className="w-12 h-12 rounded-full object-cover" src={getImage(order.tutor.userId.avatar?.path, "AVATAR")} />
                    <p className="capitalize text-nt-blue font-medium">{order.tutor.userId.firstName + " " + order.tutor.userId.lastName}</p>
                    
                </div>
                <div className="text-right">
                    <p className="text-nt-blue font-medium">{order.appointments.length + (order.appointments.length > 1 ? " Appointments" : " Appointment")}</p>
                    <p className="font-medium text-sm text-nt-red">Balance: {order.balance} €</p>
                    <p className="font-medium text-sm text-nt-red">Paypal: {order.total - order.balance} €</p>


                </div>
            </div>
            <div className="px-4 border-t-2 max-h-96 overflow-y-auto">
                <div className="flex items-center justify-between">
                <h4 className="text-nt-blue mb-4 mt-2">Summary</h4>
                <p className="text-nt-blue font-medium">Total: {order.total} €</p>
                </div>
                {order.appointments.map((v, i) => {
                    return <div className="bg-nt-grey-light mb-1 p-1 px-2 rounded text-nt-blue flex justify-between" key={i}>
                        <span>{v.date}</span>
                        <span className="text-nt-red">
                            {order.isFirstLesson && i == 0 ? (order.coursePrice / 2).toFixed(2) : order.coursePrice} €
                        </span>
                    </div>

                })}
            </div>
        </div> : ''}
    </>
}
export { PaymentResult }