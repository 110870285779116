import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NTformatDate, dateToSlice, getDeviceTimeZone, getImage, getMomentDate } from "../../utils";
import { getDayAvailability, rescheduleRange, rescheduleSingleAppointment } from "../../store/appointment/appointmentActions";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Calendar from "react-calendar";
import ReactSelect from "react-select";
import Carousel from "react-multi-carousel";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const RescheduleRangeEvent = ({ setIsReschedule, events, refreshParent, currentEvent, setIsDisabling, setIsOpen, isSchedule }) => {

    const dispatch = useDispatch();
    const { currentProfile } = useSelector((state) => state.profile)

    const [availableOptions, setAvailableOptions] = useState([]);
    const carouselRef =useRef(null)
    const { handleSubmit, control, watch, register, setValue, getValues } = useForm({
        defaultValues: {
            reschedule: events
        }
    })

    const getTakenSlices = () => {
        const currentEvents = getValues("reschedule");

        let k = currentEvents.reduce((filtred, slice) => {
            if(slice.time && slice.time.value)
                filtred.push(slice.time.label);
            return filtred;
        }, [])
        
        return k;
    }
    const getDayAvailabilityAction = (day) => {
        setAvailableOptions([]);
        
        dispatch(getDayAvailability({
            profile: currentProfile._id,
            day: NTformatDate(day)
        }))
            .unwrap()
            .then(v => {
                let takenSlices = getTakenSlices()

                if(!isSchedule){
                    let startSelected = moment(currentEvent.startStr).format("YYYY-MM-DDTHH:mm:ssZ");
                    let endSelected = moment(currentEvent.endStr).format("YYYY-MM-DDTHH:mm:ssZ");
                    let slices = v.reduce((filtred, slice) => {
                        let taken = takenSlices.includes(dateToSlice(slice.date));
                        if(!taken && (moment(slice.date).isBefore(startSelected) || moment(slice.date).isSameOrAfter(endSelected)))
                            filtred.push({
                                value: getMomentDate(slice.date),
                                label: dateToSlice(slice.date)
                            });
                        return filtred;
                    }, [])
                    setAvailableOptions(slices)
                }else{
                    let slices = v.reduce((filtred, slice) => {
                        let taken = takenSlices.includes(dateToSlice(slice.date));
                        if(!taken)
                            filtred.push({
                                value: getMomentDate(slice.date),
                                label: dateToSlice(slice.date)
                            });
                        return filtred;
                    }, [])
                    setAvailableOptions(slices)
                }
                
            })
    }



    const handleReschedule = (data) => {

        dispatch(rescheduleRange({
            start: moment(currentEvent.startStr).parseZone().format("YYYY-MM-DDTHH:mm:ssZ"),
            end: moment(currentEvent.endStr).parseZone().format("YYYY-MM-DDTHH:mm:ssZ"),
            action: "reschedule",
            timezone: getDeviceTimeZone(),
            events: data.reschedule.map((v) => {
                return ({_id: v._id, time: v.time, message: v.message, reason: v.reason})})
        }))
            .unwrap()
            .then((v) => {
                setIsDisabling(false);
                setIsOpen(false)
                setIsReschedule(false)
                refreshParent();
            })
        // dispatch(rescheduleSingleAppointment(data))
        //     .unwrap()
        //     .then(v => refreshParent())
    }

    const {
        fields: rescheduleFields,
        append: rescheduleAppend,
        remove: rescheduleRemove
    } = useFieldArray({
        control,
        name: "reschedule",
    });

    const ReasonOptions = [
        { value: 0, label: "The student asked to reschedule" },
        { value: 1, label: "Health issues" },
        { value: 2, label: "I am taking leave" },
        { value: 3, label: "I wish to change my rates" },
        { value: 4, label: "I have another commitment" },
        { value: 5, label: "I have a technical problem" },
        { value: 6, label: "Other" },
    ]

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const Appointment = ({ event }) => {
        return <div className='flex items-center gap-2 mb-4'>
            <img className='w-12 h-12 rounded-full object-cover' src={getImage(event.avatar, "AVATAR")} />
            <div className=''>
                <h4 className='capitalize text-nt-blue'>{event.title}</h4>
                <span className='text-nt-grey'>{moment(event.date).parseZone().format("YYYY-MM-DD HH:mm").toString()}</span>
            </div>
        </div>
    }

    const ButtonGroup = ({ next, previous, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="absolute top-3 flex gap-3 right-0">
                <button type="button" className={` bg-transparent ${currentSlide === 0 ? "opacity-60 pointer-events-none" : ""} text-nt-blue`} onClick={() => previous()}><FiChevronLeft size={25} /></button>
                <button type="button" className={`${currentSlide === rescheduleFields.length - 1 ? "opacity-60 pointer-events-none" : ""} text-nt-blue`} onClick={() => next()}><FiChevronRight size={25} /></button>
            </div>
        );
    };

    const nextSlide = () => {
        if (carouselRef.current) {
          carouselRef.current.next();
        }
    };
    return <div>
        <form className="flex flex-col gap-5" onSubmit={handleSubmit((data) => handleReschedule(data))}>
            <h3 className="text-nt-blue">Reschedule Courses</h3>
            <Carousel
                ref={carouselRef}
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                autoPlay={false}
                arrows={false}
                autoPlaySpeed={1000}
                keyBoardControl={false}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                deviceType={"tablet"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customButtonGroup={<ButtonGroup />}
            >
                {
                    rescheduleFields.map((event, index) => {
                        // const dateField = watch(`reschedule.${index}.date`)
                        const timeField = watch(`reschedule.${index}.time`)
                        const reasonField = watch(`reschedule.${index}.reason`)
                        return <div className="flex flex-col w-full" key={index}>
                            <Appointment event={event} />
                            <div className="w-full">
                                <Controller
                                    control={control}
                                    name={`reschedule.${index}.date`}
                                    render={({ field: { onChange, value } }) => (
                                        <Calendar className={"nt-calendar w-full"}
                                            onChange={(e) => { onChange(e); getDayAvailabilityAction(e); setValue(`reschedule.${index}.time`, null) }}
                                            view='month'
                                            minDate={moment().add(1, "day").add(1, "hour").toDate()}
                                            maxDate={moment().add(1, "year").add(1, "hour").toDate()}
                                        />
                                    )}
                                />


                            </div>
                            <div className="w-full mt-5">
                                <Controller
                                    control={control}
                                    name={`reschedule.${index}.time`}
                                    render={({ field: { onChange, value } }) => (
                                        <ReactSelect
                                            maxMenuHeight={200}
                                            className="w-full"
                                            placeholder="Time"
                                            required="true"
                                            onChange={onChange}
                                            value={value || ''}
                                            options={availableOptions}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={`reschedule.${index}.reason`}
                                    render={({ field: { onChange, value } }) => (
                                        <ReactSelect
                                            maxMenuHeight={200}
                                            className="w-full mt-5"
                                            placeholder="Reason"
                                            required={true}
                                            onChange={onChange}
                                            value={value || ''}
                                            options={ReasonOptions}
                                        />
                                    )}
                                />
                                {/* <input name="_id" {...register(`reschedule.${index}._id`)} type="hidden" value={event._id} /> */}
                                <textarea name="message" {...register(`reschedule.${index}.message`)} rows={6} className="w-full mt-5 flex-grow border-nt-grey border-2 rounded p-1" placeholder="Message"></textarea>
                            </div>
                            {(rescheduleFields.length > 1 && index + 1 < rescheduleFields.length) && <button type="button" disabled={(!timeField || !timeField.value || !reasonField || !reasonField.value )} className="w-full bg-nt-blue text-white px-2 py-3 rounded" onClick={() => nextSlide()}>Next</button>}
                            {(rescheduleFields.length == 1 || index + 1 == rescheduleFields.length) && <button disabled={getTakenSlices().length != rescheduleFields.length} className="w-full bg-nt-blue text-white px-2 py-3 rounded">Reschedule</button>}

                        </div>

                    })
                }

            </Carousel>

            <div className="flex justify-center gap-5">
                {/* {(events.length == 1 || carousel.currentSlide + 1 === events.length) && <button className="w-40 bg-nt-blue text-white px-2 py-3 rounded">Reschedule</button>} */}
                <button type="button" className="w-full bg-nt-grey text-white px-2 py-3 rounded" onClick={() => setIsReschedule(false)}>Cancel</button>
            </div>
        </form>

    </div >
}
export { RescheduleRangeEvent }