import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/auth/authActions';
import Styles from './navbar.module.scss'
import Logo from '../../assets/app/logo.png'
import { FiHome, FiCalendar, FiMessageSquare, FiSliders, FiLogOut, FiMenu, FiX, FiHeadphones } from 'react-icons/fi';
import { getImage, getProfileAdmin, getProfileTutor } from '../../utils';
import { MenuItem } from './menuItem';
import { useState } from 'react';
import { Wallet } from '../../pages/wallet/wallet';
import { FaMoneyBillTransfer, FaMoneyCheck, FaQuestion } from 'react-icons/fa6';
import { switchToTutorProfile } from '../../store/profile/profileActions';
import { goToSupport } from '../../store/chat/chatActions';


const NavbarStudent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { user } = useSelector((state) => state.auth);
	const [menuOpen, setMenuOpen] = useState(false);

	const tutorProfile = getProfileTutor();

	const logoutHandler = () => {
		dispatch(logoutUser());
	};

	const switchToTutor = () => {
		if (tutorProfile.status === -1)
			navigate("/switch/tutor")
		else
			dispatch(switchToTutorProfile())
				.then(() => navigate("/"))
	}

	const SwitchProfile = () => {
		if (tutorProfile !== false && tutorProfile.status === 0)
			return <div className='inline-table mx-auto self-end left-[25px] bg-nt-grey w-[90%] mb-5 text-xs text-white py-2 px-5 rounded select-none'>
				Tutor account under review.
			</div>
		else if (tutorProfile === false || tutorProfile.status === -1)
			return <div className='inline-table mx-auto self-end left-[25px] bg-nt-blue w-[90%] mb-5 text-sm text-white py-2 px-5 rounded cursor-pointer'>
				<Link to="/switch/tutor" className='no-underline text-white'>Become a Tutor</Link>
			</div>
		else if (tutorProfile !== false && tutorProfile.status === 1)
			return <div onClick={() => switchToTutor()} className='inline-table mx-auto self-end left-[25px] bg-nt-blue w-[90%] mb-5 text-sm text-white py-2 px-5 rounded cursor-pointer'>
				Switch to Tutor
			</div>
	}
	const Support = () => {
		return <div title="Support" className='fixed top-2 xl:top-6 shadow-xl hover:bg-nt-blue hover:text-white right-2 xl:right-4 flex items-center justify-center rounded-full z-50 bg-white w-10 h-10 cursor-pointer'onClick={() => handleSupport()}><FaQuestion  size={20} /></div>
	}
	
	const handleSupport = () => {
		if(!location.pathname.includes("/chat")){
			dispatch(goToSupport())
			.unwrap()
			.then((v) => navigate("/chat/" + v._id))
		}
	}
	return (
		<>
		
			<div className='fixed xl:hidden bg-white w-full flex z-50 justify-center items-center py-2'>
				<div className='xl:hidden'>
					<button onClick={() => setMenuOpen((old) => !old)} className='absolute left-2 top-2 p-2 rounded-full bg-nt-blue text-white grid place-items-center shadow-md'>
						{!menuOpen ? <FiMenu size={24} /> : <FiX size={24} />}
					</button>
				</div>
				<img src={Logo} className='self-center justify-self-center' />
				<Support />
			</div>
			<div className={`w-[300px] overflow-y-auto h-[calc(100%-3.5rem)] xl:min-h-screen rounded-tr-3xl rounded-br-3xl flex flex-col bg-white 
				items-start p-6 z-50 fixed transition-transform top-14 xl:top-0
			${menuOpen ? "translate-x-0" : " -translate-x-full"} xl:translate-x-0`}>
				<img src={Logo} className='hidden xl:block mb-5' />
				<span className='hidden xl:block'><Support /></span>
				<div className={'mb-4 w-full flex-1'}>
					<ul className='space-y-3'>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Dashboard" Icon={FiHome} link='/' />
						</li>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Tutors" Icon={FiSliders} link='/tutors' />
						</li>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Appointments" Icon={FiCalendar} link='/appointment' />
						</li>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Chat" Icon={FiMessageSquare} link='/chat' />
						</li>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Transaction" Icon={FaMoneyBillTransfer} link='/wallet' notification={Wallet} />
						</li>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Withdraw" Icon={FaMoneyCheck} link='/withdraw' />
						</li>
						<li className='w-full select-none text-nt-blue hover:bg-nt-red hover:text-white group rounded'>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Settings" Icon={FiSliders} link='/settings' />
						</li>
					</ul>
				</div>

				<SwitchProfile />

				<div className={'flex gap-2 items-center justify-between text-sm w-full border-t-2 border-nt-grey-light pt-2'}>

					<img src={getImage(user.avatar, "AVATAR")} className='w-12 h-12 rounded-full object-cover' />
					<div className={'flex flex-col grow'}>
						<div className={'text-nt-blue font-semibold capitalize'}>{user.firstName + ' ' + user.lastName}</div>
						<div className={'text-nt-grey'}>Student</div>
					</div>
					<div className='w-8'>
						<FiLogOut onClick={() => logoutHandler()} className={Styles.logoutBtn + " cursor-pointer"} />
					</div>
				</div>


			</div>
		</>
	);
};

export { NavbarStudent };
