import { useSelector } from 'react-redux';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Logo from "../../assets/app/logo.png"
import { useEffect } from 'react';

export const PublicRoute = ({ component: Component, ...props }) => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const location = useLocation()

	useEffect(() => {
		if(user){
			console.log(location.pathname.replace("/public", ""))
			navigate(location.pathname.replace("/public", ""), { replace: true })
		}
	}, [])

	return (
		<div className='w-full bg-[#f0f0f0] min-h-screen'>
			<div className='px-4 fixed w-full bg-white mb-5 z-50 shadow'>
				<div className='flex justify-between items-center 2xl:w-4/6 mx-auto py-4'>
					<Link to={"https://nativtalk.com"} >
						<img src={Logo} />
					</Link>
					<Link to={"/"} className='bg-nt-blue text-white no-underline py-2 px-4 rounded'>
						Sign in
					</Link>
				</div>
			</div>
			<div className='w-full px-4 py-24'>
				<Component {...props} />
			</div>
		</div>
	);
};
