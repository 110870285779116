import SelectItemImg from '../../assets/chat/select-conversation.png'
import { useDispatch, useSelector } from 'react-redux';
import { VirtuosoMsg } from './vituoso';
import { useEffect, useMemo } from 'react';
import { getFullChat } from '../../store/chat/chatActions';

const ChatBox = ({ pushFile }) => {

    const currentChat = useSelector((state) => state.chat.currentChat)
    const dispatch = useDispatch()
    const NoChatSelected = useMemo(() => <div className="flex items-center place-content-center flex-col opacity-40">
        <img className='max-h-[50vh]' src={SelectItemImg} />
        <h3 className='text-nt-blue text-center'>Please select conversation</h3>
    </div>, [])

    const SingleChat = useMemo(() => () => { return <VirtuosoMsg messagesList={currentChat?.messages || []} /> }, [currentChat?._id | ""])

    const Chat = () => {
        return (
            <div className='w-full h-full flex flex-col justify-end'>
                <VirtuosoMsg messagesList={currentChat?.messages || []} />
            </div>
        )
    }
    useEffect(() => {
        if(currentChat && currentChat._id)
            dispatch(getFullChat(currentChat._id))
    }, [])

    return <div className="w-full h-full grow max-h-[90vh] md:max-h-max">
        {currentChat ? <Chat /> : NoChatSelected}
    </div>


}

export { ChatBox }