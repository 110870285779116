import { RatingStars } from "./ratingStars"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { addReview, getFullTutorProfile } from "../../store/profile/profileActions";
import { getImage } from "../../utils";

const ReviewsBox = ({ profile, canReview = true }) => {
    const dispatch = useDispatch();
    const [reviewsList, setReviewsList] = useState(profile.reviews)
    const [isSent, setIsSent] = useState(false)
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    const [reviewText, setReviewText] = useState("");
    const [wifiReview, setWifiReview] = useState(1);
    const [attendanceReview, setAttendanceReview] = useState(1);
    const [qualityReview, setQualityReview] = useState(1);
    const [interactionReview, setInteractionReview] = useState(1);

    const ReviewsCarousel = useMemo(() => {
        return
    }, [])

    const sendReview = () => {
        if (reviewText.trim() !== "") {
            const review = {
                text: reviewText,
                wifi: wifiReview,
                quality: qualityReview,
                attendance: attendanceReview,
                interaction: interactionReview,
                tutor: profile._id,
            }
            dispatch(addReview(review))
                .unwrap()
                .then(() => {
                    dispatch(getFullTutorProfile({profileId: profile._id}))
                    .unwrap()
                    .then((v) => setReviewsList(v.reviews))
                    setIsSent(true)
                    // setReviewsList((k) => k.unshift(v))
                })
        }
    }

    const reviewsMoy = reviewsList.length > 0 ?
        Math.round((reviewsList.reduce((ac, v) => ((v.wifi + v.interaction + v.attendance + v.quality) / 4 + ac), 0)) / reviewsList.length)
        : 1;

    return (
        <div className="bg-white rounded-xl p-5">
            <div className="flex items-center gap-5 mb-5">
                <h3 className="text-nt-blue">Reviews ({reviewsList.length})</h3>
                <RatingStars active={reviewsMoy} />
            </div>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container cursor-pointer"
                arrows={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {reviewsList.map((review, i) => <div key={i} className="px-5">
                    <div className="border-[1px] p-3 border-nt-blue rounded-xl">
                        <div className="flex justify-between items-center">

                            <div className="flex gap-5 items-center">
                                <img src={getImage(review.student.userId.avatar?.path, "AVATAR")} className="select-none rounded-full w-[50px] h-[50px] border-nt-grey-light border-[2px]" />
                                <h5 className="select-none capitalize text-nt-blue">{review.student.userId.firstName + " " + review.student.userId.lastName}</h5>
                            </div>
                            <div className="select-none ">
                                <RatingStars active={Math.round((review.wifi + review.attendance + review.quality + review.interaction) / 4)} />
                            </div>
                        </div>
                        <p className="font-size-[12px] select-none mt-3">
                            {review.text}
                        </p>
                    </div>
                </div>)}
            </Carousel>

            {(canReview && !isSent) && <div className="p-5 rounded-xl border-nt-blue border-2 mt-5">
                <div className="flex justify-between items-center mb-5">
                    <h4 className="text-nt-blue mb-5">Leave a review</h4>
                    <button onClick={() => sendReview()} className="text-white bg-nt-blue rounded px-5 py-3">Send</button>
                </div>
                <div className="flex gap-5 flex-row justify-between items-center">

                    <div className="flex flex-col">
                        <div className="">
                            <div className="flex justify-between">
                                <h5 className="text-nt-blue">Wifi Connection Quality</h5>
                                <RatingStars setValueContainer={(v) => setWifiReview(v)} canChange={true} h="h-5" w="w-5" />
                            </div>
                            <p className="text-nt-grey">Rate the reliability and speed of the Wi-Fi during the activity.</p>
                        </div>

                        <div className="">
                            <div className="flex justify-between">
                                <h5 className="text-nt-blue">Attendance</h5>
                                <RatingStars setValueContainer={(v) => setAttendanceReview(v)} canChange={true} h="h-5" w="w-5" />
                            </div>
                            <p className="text-nt-grey">Evaluate how well the tutor managed attendance</p>
                        </div>

                        <div className="">
                            <div className="flex justify-between">
                                <h5 className="text-nt-blue">Course Material Quality</h5>
                                <RatingStars setValueContainer={(v) => setQualityReview(v)} canChange={true} h="h-5" w="w-5" />
                            </div>
                            <p className="text-nt-grey">Assess the availability and usefulness of educational materials—textbooks, audio, PDFs, etc.</p>
                        </div>

                        <div className="">
                            <div className="flex justify-between">
                                <h5 className="text-nt-blue">Human Interaction</h5>
                                <RatingStars setValueContainer={(v) => setInteractionReview(v)} canChange={true} h="h-5" w="w-5" />
                            </div>
                            <p className="text-nt-grey">Rate the quality of interpersonal interactions with instructor kindness, attentiveness, and overall positive engagement</p>
                        </div>

                    </div>
                </div>

                <textarea rows={"5"} onChange={(e) => setReviewText(e.target.value)} placeholder="Write a review" className="border-2 border-nt-grey w-full rounded-xl mt-5 p-2" />
            </div>}
            {isSent && <h4 className="text-nt-blue text-center mt-5">Thank you for your review</h4>}
        </div>
    )
}

export { ReviewsBox }