import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FileInput, SelectInput, TextInput, PhoneInput } from "../../components/inputs";
import { successToast } from "../../utils";
import { updateUser } from "../../store/auth/authActions";
import { Toaster } from "react-hot-toast";
import countries from '../../utils/data/countries.json'

const UserSettings = () => {

    const { user } = useSelector((state) => state.auth);
    const { currentProfile } = useSelector((state) => state.profile);

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        watch,
        setError,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            userId: user._id,
            country: user.country,
            avatar: user.avatar,
            phone: user.phone || null,
            email: user.email
        }
    });

    const onSubmit = async (data) => {
        console.log("data ", data);
        dispatch(updateUser(data))
            .unwrap()
            .then(() => {
                successToast("Informations changed successfully");
                setValue("password", "")
            })
    };


    const options = countries.map((country) => { return { value: country.iso, label: country.name } })

    return (
        <>
            <Toaster containerStyle={{zIndex: 9999999999 }} />
            <form noValidate id="user-form" className={"bg-white rounded-2xl p-5 w-full xl:w-2/4 mx-auto flex flex-col"} onSubmit={handleSubmit(onSubmit)}>
                <FileInput
                    name="avatar"
                    label="Avatar"
                    errors={errors}
                    register={register}
                    setError={setError}
                    control={control}
                    defaultValue={watch("avatar")}
                    style="avatar"
                    required
                />

                <div className="flex flex-col md:flex-row xl:flex-col 2xl:flex-row gap-2 md:gap-10 xl:gap-2 2xl:gap-10 mt-5">
                    <TextInput
                        name="firstName"
                        label="First Name"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                    <TextInput
                        name="lastName"
                        label="Last Name"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                </div>

                <TextInput
                    name="email"
                    label="Email"
                    errors={errors}
                    type="email"
                    register={register}
                    control={control}
                    validationSchema={{
                        required: "This field is required"
                    }}
                />

                {currentProfile.role !== 1 ?
                    <>
                        <Controller
                            control={control}
                            name={"country"}

                            render={({ field }) => (
                                <SelectInput
                                    name="country"
                                    label="Country"
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    options={options}
                                    onChangeSelect={event => {
                                        field.onChange(event.value)
                                    }}
                                    validationSchema={{
                                        required: "This field is required"
                                    }}
                                // required:
                                />
                            )}
                        />

                        <PhoneInput
                            name="phone"
                            label="Phone"
                            className="phoneInput"
                            errors={errors}
                            register={register}
                            control={control}
                        />
                    </> : ''}

                <TextInput
                    name="password"
                    label="New Password"
                    className=""
                    type={"password"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                        validate: (v) => (v.trim() != "" && v.length < 8) ? "The password must be at least 8 characters long": true
                    }}
                    control={control}
                />

                <button className="bg-nt-blue text-white p-2 px-4 rounded self-center">
                    Save
                </button>

            </form>

        </>
    )
}
export { UserSettings }