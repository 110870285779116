import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAppointmentNotification } from "../../store/notification/notificationActions";

import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from "@table-library/react-table-library/pagination";

import { FiArrowDown, FiArrowUp, FiCreditCard, FiDollarSign, FiEye, FiUsers } from "react-icons/fi";
import { GiReceiveMoney } from "react-icons/gi";
import { FaMoneyBillTransfer, FaMoneyBillTrendUp } from 'react-icons/fa6';

import { getTransactionList, getWalletStats } from "../../store/wallet/walletActions";
import { NumberIcon } from "../../components/widgets";
import NotFoundWidget from '../../components/widgets/NotFound';
import { getLocalDate, getProfileStudent, getProfileTutor } from "../../utils";

import Styles from '../users/users.module.scss'
import { TablePagination } from "../../components/global/table-pagination";

const Wallet = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTransactionList())
            .unwrap()
            .then((v) => setNodes(v))
        dispatch(resetAppointmentNotification())
        dispatch(getWalletStats())
    }, [])

    const TransactionAmount = ({ type, amount }) => {
        if ([-1, 1].includes(type)) {
            return <div className="text-nt-blue flex">
                <FiArrowUp className="text-nt-blue" size={20} />
                {amount + " €"}
            </div>
        } else if ([-2, 0, 2, 3].includes(type)) {
            return <div className="text-nt-red flex">
                <FiArrowDown className="text-nt-red" size={20} />
                {amount + " €"}
            </div>
        }
    }
    const [nodes, setNodes] = useState([]);

    const COLUMNS = [
        {
            label: 'Transaction Date',
            renderCell: (item) => <p className="capitalize font-medium text-nt-blue">{getLocalDate(item.createdAt)}</p>,
            pinLeft: true,

        },
        {
            label: 'Amount',
            renderCell: (item) => <TransactionAmount type={item.type} amount={item.amount} />,
            pinLeft: true,

        },
        {
            label: 'Type',
            renderCell: (item) => <TypeTag type={item.type} />,
            pinLeft: true,
        },
        {
            label: 'Payment Reason',
            renderCell: (item, index) => {
                return <>
                    {item.type !== -2 ? <Link className={Styles.viewBtn} to={item.type == 3 ? `/order/payment/${item.appointment}` :`/appointment/detail/${item.appointment}`}>
                        <FiEye />
                        <span>View</span>
                    </Link> : ""}
                </>
            },
            pinLeft: true,

        },
    ];

    const TypeTag = ({ type }) => {
        if (type === 2 || type === 1) {
            return <span className={`bg-green text-white px-2 rounded py-1 inline-block`}>Payment</span>
        } else if (type === 0) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1 inline-block`}>Platform</span>
        } else if (type === -1) {
            return <span className={`bg-green text-white px-2 rounded py-1 inline-block`}>Refunded</span>
        } else if (type === -2) {
            return <span className={`bg-red text-white px-2 rounded py-1 inline-block`}>Withdraw</span>
        } else if (type === 3) {
            return <span className={`bg-nt-blue opacity-70 text-white px-2 rounded py-1 inline-block`}>Booking</span>
        }
    }
    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`,
        Table: `--data-table-library_grid-template-columns: auto 200px 200px 200px; width:auto;min-width:auto;min-height:auto;`
    }]);

    let data = { nodes };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
    });


    const pageButtonCount = 2;
    const totalPages = pagination.state.getTotalPages(data.nodes)
    const pages = pagination.state.getPages(data.nodes);

    const { balance, income, withdraws, pending, refunded, expenses, paypal, pendingWithdraw } = useSelector((state) => state.wallet)
    const { currentProfile } = useSelector((state) => state.profile)

    let tutorProfile = getProfileTutor();
    let studentProfile = getProfileStudent();

    const StatsWidgets = () => {
        
        if(tutorProfile !== false && studentProfile !== false){
            return (<div className={`general-informations mb-5 grid lg:grid-cols-3 sm:grid-cols-2 gap-4`}>
                <NumberIcon title="Balance" number={balance + "€"} Icon={FiCreditCard} />
                <NumberIcon title="Withdraws" number={withdraws + "€"} Icon={FaMoneyBillTransfer} />
                <NumberIcon title="Pending Withdraw" number={pendingWithdraw + "€"} Icon={FiDollarSign} />
                <NumberIcon title="Income" number={income + "€"} Icon={FaMoneyBillTrendUp} />
                <NumberIcon title="Paid" number={expenses + "€"} Icon={GiReceiveMoney} />
                <NumberIcon title="Pending Payment" number={pending + "€"} Icon={FiDollarSign} />
                
            </div>)
        }else if(currentProfile?.role && currentProfile?.role == 2){
            return (<div className={`general-informations mb-5 grid md:grid-cols-3 gap-4`}>
                <NumberIcon titleClassName="text-[20px]" title="Balance" number={balance + "€"} Icon={FiCreditCard} />
                <NumberIcon titleClassName="text-[20px]" title="Income" number={income + "€"} Icon={FaMoneyBillTrendUp} />
                <NumberIcon titleClassName="text-[20px]" title="Withdraws" number={withdraws + "€"} Icon={FaMoneyBillTransfer} />
                <NumberIcon titleClassName="text-[20px]" numberClassName="text-[20px]" title="Pending Withdraw" number={pendingWithdraw + "€"} Icon={FiDollarSign} />
            </div>)
        }else if(currentProfile?.role && currentProfile?.role == 3){
            return (<div className={`general-informations mb-5 grid lg:grid-cols-3 sm:grid-cols-2 gap-4`}>
                <NumberIcon titleClassName="text-[16px]" numberClassName="text-[20px]" title="Balance" number={balance + "€"} Icon={FiCreditCard} />
                <NumberIcon titleClassName="text-[16px]" numberClassName="text-[20px]" title="Withdraws" number={withdraws + "€"} Icon={FaMoneyBillTransfer} />
                <NumberIcon titleClassName="text-[16px]" numberClassName="text-[20px]" title="Pending Payment" number={pending + "€"} Icon={FiDollarSign} />
                <NumberIcon titleClassName="text-[16px]" numberClassName="text-[20px]" title="Pending Withdraw" number={pendingWithdraw + "€"} Icon={FiDollarSign} />
                <NumberIcon titleClassName="text-[16px]" numberClassName="text-[20px]" title="Paid" number={expenses + "€"} Icon={GiReceiveMoney} />
                <NumberIcon titleClassName="text-[16px]" numberClassName="text-[20px]" title="Paypal" number={paypal + "€"} Icon={GiReceiveMoney} />
            </div>)
        }
    }
    return (
        <div className="w-full">
            {/* <div className={`general-informations mb-5 grid lg:grid-cols-4 sm:grid-cols-2 gap-4`}>
                <NumberIcon title="Balance" number={balance + "€"} Icon={FiCreditCard} />
                {currentProfile?.role && currentProfile?.role == 2 && <NumberIcon title="Income" number={income + "€"} Icon={FaMoneyBillTrendUp} />}
                {currentProfile?.role && <NumberIcon title="Withdraws" number={withdraws + "€"} Icon={FaMoneyBillTransfer} />}
                {currentProfile?.role && currentProfile?.role == 3 && <NumberIcon title="Pending Payment" number={pending + "€"} Icon={FiDollarSign} />}
                {currentProfile?.role && currentProfile?.role == 3 && <NumberIcon title="paid" number={expenses + "€"} Icon={GiReceiveMoney} />}
                {currentProfile?.role && currentProfile?.role == 3 && <NumberIcon title="Refunded" number={refunded + "€"} Icon={GiReceiveMoney} />}
            </div> */}
            <StatsWidgets />
            <div className="bg-white p-4 rounded-2xl">
                <div className={Styles.tableHeader}>
                    <h2>Transactions List</h2>
                </div>

                <CompactTable 
                    columns={COLUMNS} 
                    layout={{ custom: true, horizontalScroll: true, }} 
                    data={data} 
                    theme={theme} 
                    pagination={pagination}
                    key={"row-data"} 
                />
                { nodes.length === 0 ? <NotFoundWidget message="No Transaction History" /> : null }
                {totalPages > 0 ? <TablePagination
                    pageButtonCount={pageButtonCount}
                    totalPages={totalPages}
                    pagination={pagination}
                    pages={pages}
                /> : null}
            </div>
        </div>

    )
}

export { Wallet }