import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast, successToast } from '../../utils';
import { confirmUser, loginUser, registerUser, resendConfirmUser } from '../../store/auth/authActions';
import Styles from './register.module.scss';
import useDigitInput from 'react-digit-input';

export const ConfirmEmail = () => {
    const dispatch = useDispatch();
    const { loading, user, error } = useSelector((state) => state.auth);
    const [validationError, setValidationError] = useState('');
    const [resendLoading, setResendLoading] = useState(false);
    const [value, onChange] = useState('');
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange,
    });

    const confirmEmailAction = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(confirmUser({
            code: value,
            userId: user._id,
        }))
            .unwrap()
            .then(() => {
                successToast("Email Confirmed")
            })
            .catch((errorData) => {
                errorToast(errorData.error);
            });
    };

    const resendCode = (e) => {
        e.preventDefault() 
        setResendLoading(true)
        dispatch(resendConfirmUser({
            userId: user._id,
        }))
            .unwrap()
            .then(() => {
                setResendLoading(false);
                successToast("Code Sent Again.")
            })
            .catch((errorData) => {
                setResendLoading(false);
                errorToast(errorData.error);
            });
    }

    return (

        <form onSubmit={confirmEmailAction} className='text-center'>
            <h1 className='text-nt-blue text-center mb-5'>Confirm Email</h1>
            <div className='text-center mb-5'>
                <label className="block text-primary-grey text-[13px] font-medium pb-1">Code</label>
                <div className="relative">
                    <div className={`input-group flex confirmCode ${Styles.confirmCode}`}>
                        <input inputMode="decimal" autoFocus {...digits[0]} />
                        <input inputMode="decimal" {...digits[1]} />
                        <input inputMode="decimal" {...digits[2]} />
                        <input inputMode="decimal" {...digits[3]} />
                        <input inputMode="decimal" {...digits[4]} />
                        <input inputMode="decimal" {...digits[5]} />
                    </div>
                </div>
            </div>
            {validationError && <p className="text-left text-red-500">{validationError}</p>}
            <button disabled={value.trim().length !== 6} type="submit" className="primaryButton">
                {loading ? 'Loading...' : 'Confirm'}
            </button>
            <button className="mt-4 mb-8 text-nt-blue bg-transparent disabled:bg-transparent disabled:opacity-70" disabled={resendLoading} onClick={(e) => resendCode(e)}>Resend Code</button>
        </form>
    );
};
